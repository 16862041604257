
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class MediaImage extends Vue {
  @Prop(String) readonly imgWidth!: string;
  @Prop(String) readonly imgHeight!: string;
  @Prop(String) readonly imgSrc!: string;
  @Prop({ type: String, default: "contain" }) imgSize!: string;

  $refs!: {
    imageWrap: HTMLElement;
    mediaImage: HTMLElement;
  };

  mounted() {
    const { imageWrap, mediaImage } = this.$refs;
    const bgImg = new Image();
    bgImg.onload = () => {
      mediaImage.style.backgroundImage = `url(${bgImg.src})`;
      imageWrap.classList.remove("isLoad");
    };

    bgImg.src = this.imgSrc;
  }
}
