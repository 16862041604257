
import { Component, Vue, Prop } from "vue-property-decorator";
import MediaImage from "./MediaImage.vue";

@Component({
  components: {
    MediaImage
  }
})
export default class PortfolioCard extends Vue {
  @Prop(Object) readonly data!: object;
  @Prop(Number) readonly index!: number;

  get formatCardIndex() {
    const num = this.index + 1;
    return num < 10 ? `0${num}` : `${num}`;
  }
}
