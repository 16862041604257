const portfolios: Object = {
  // atcc: {
  //   id: "atcc",
  //   projectName: "ATCC 全國大專院校商業個案大賽",
  //   img: "/static/portfolio/atcc.jpg",
  //   year: "2020",
  //   link: "https://signup.atcc.co",
  //   isOnline: true
  // },
  lebecca_store: {
    id: "lebecca_store",
    projectNameLangId: "portfolio.lebecca",
    img: "/static/portfolio/lebecca_store.png",
    year: "2022",
    link: "https://store.lebecca.url.tw/",
    isOnline: true
  },
  molong_store: {
    id: "molong_store",
    projectNameLangId: "portfolio.molong",
    img: "/static/portfolio/molong_store.jpg",
    year: "2020",
    link: "https://www.pojiali.com.tw/",
    isOnline: true
  },
  molong_faucet: {
    id: "molong_faucet",
    projectNameLangId: "portfolio.molongLineBot",
    img: "/static/portfolio/molong_faucet.jpeg",
    year: "2019",
    link: "https://liff.line.me/1655168823-1loQrYEV",
    isOnline: true
  },
  // tsmc_ic_the_future: {
  //   id: "tsmc_ic_the_future",
  //   projectName: "台積電「前瞻佈局」大賽",
  //   img: "/static/portfolio/tsmc_ic_the_future.jpg",
  //   year: "2019",
  //   link: "https://tsmc-ic-the-future.com/",
  //   detail:
  //     "為台積電製作首屆前瞻佈局大賽，為一個線上影音學習平台。" +
  //     "<a target='_blank' href='https://technews.tw/2019/09/10/tsmc-and-microsoft-cadence/'>新聞報導</a>。" +
  //     "<br />" +
  //     "我們也提供了後台編輯器，讓台積電內部人員能追蹤參賽者的學習狀況。",
  //   detailImgs: ["/static/portfolio/tsmc_ic_the_future_detail_1.jpg"],
  //   isOnline: true
  // },
  // tsmc_careers: {
  //   id: "tsmc_careers",
  //   projectName: "台積電工程人才招募計畫",
  //   img: "/static/portfolio/tsmc_careers.jpg",
  //   year: "2019",
  //   link: "",
  //   detail:
  //     "為 2019 台積電工程人才招募計畫製作全新的徵才網站。" +
  //     "<br />" +
  //     "我們也提供了後台編輯器，讓台積電內部人員能快速的瀏覽與搜尋報名者履歷並進行篩選。",
  //   detailImgs: ["/static/portfolio/tsmc_careers_detail_1.jpg"],
  //   isOnline: false
  // },
  // alcon: {
  //   id: "alcon",
  //   projectName: "Alcon 不眨眼挑戰賽",
  //   img: "/static/portfolio/alcon.jpg",
  //   year: "2019",
  //   link: "",
  //   detail:
  //     "為愛爾康公司製作不眨眼挑戰小遊戲。我們搭配最新的深度學習技術去判斷使用者眨眼，是目前網頁開發上顯有的嘗試。" +
  //     "<br />" +
  //     "由於活動已結束由官方撤下，但 RAS 搭上 2019 五月底在 instagram 掀起的 <a target='_blank' href='https://www.kocpc.com.tw/archives/260870'>flying face</a> 熱潮，" +
  //     "我們便快速的製作遊戲原型去示範眨眼判斷如何在網頁上實現，" +
  //     "demo 連結<a target='_blank' href='https://quirky-nightingale-11dae5.netlify.com/'>請點這</a>。",
  //   detailImgs: [],
  //   isOnline: false
  // },
  maclab: {
    id: "maclab",
    projectNameLangId: "portfolio.musicAILab",
    img: "/static/portfolio/maclab.jpg",
    year: "2018",
    link: "https://musicai.citi.sinica.edu.tw",
    detail:
      "此專案為中研院-音樂與人工智慧實驗室打造全新的實驗室網頁。（<a target='_blank' href='http://mac.citi.sinica.edu.tw/'>舊網頁</a>)" +
      "<br />" +
      "除了將原本的首頁、文獻與成員介紹進行樣式改版，也為實驗室需多打造了專案與部落格頁面。" +
      "在主配色上改用舊網頁標題的紅色，在首頁以大圖表現實驗室是音樂與電腦的結合。此專案全數頁面設計為 RAS 完全主導" +
      "<br />" +
      "後台系統方面，我們提供編輯介面讓實驗室成員可以輕易地對網站內容做即時的增刪、修改。" +
      "",
    detailImgs: ["/static/portfolio/maclab_detail_1.jpg"],
    isOnline: false
  },
  ntu_poll: {
    id: "ntu_poll",
    projectNameLangId: "portfolio.ntuElection",
    img: "/static/portfolio/ntu.jpg",
    year: "2017",
    link: "",
    detail:
      "台大校長 2018 即將改選，僅有少數學生代表能參與投票，學生會為了傾聽大眾學生的聲音，便與我們合作製作了學生投票網站。" +
      "<br />" +
      "我們提供的投票系統能承受同時多人投票 (High QPS) 的情況，並且很好的處理了可能會產生的資料一致性問題 (race condition)，確保每位候選人都有最正確、最即時的票數。" +
      "另外我們也製作了 Line 機器人供學生會成員查看目前投票狀態。而專案頁面設計也是 RAS 完全主導。",
    detailImgs: [],
    isOnline: false
  }
};

export { portfolios };
