
import { Component, Vue } from "vue-property-decorator";
import LayoutWithNavAndFooter from "@/layout/LayoutWithNavAndFooter.vue";
import PortfolioCard from "@/components/PortfolioCard.vue";
import Title from "@/components/Title.vue";
import { portfolios } from "@/config/portfolio";

@Component({
  components: {
    LayoutWithNavAndFooter,
    PortfolioCard,
    Title
  }
})
export default class Portfolio extends Vue {
  portfolios = portfolios;
}
